// buttonController.jsx
import * as React from 'react';
import { StoreContext } from '../../store/storeContext';
import { checkUser } from '../../services/services';
import { check } from 'prettier';
import dayjs from 'dayjs';
const useButtonController = () => {
    const { handleSetOpenModal, setUser, displayButton } = React.useContext(StoreContext);





    return {
        handleSetOpenModal,
        displayButton
    };
};

export default useButtonController;
