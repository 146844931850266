// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#standard-search {
    background-color: white;
}

#error-border {
    border: 2px solid #ff3e00;
    border-radius: 20px;
}

/* #correct-border {
    border: 2px solid #00ff00;
} */
/* .css-tlc64q-MuiPaper-root-MuiDialog-paper{
    max-width: 800px !important;
} */
#saveButtonDropOff {
    color: white !important;
}
#cleanButtonDropOff {
    color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modal.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;;GAEG;AACH;;GAEG;AACH;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;AAC3B","sourcesContent":["#standard-search {\n    background-color: white;\n}\n\n#error-border {\n    border: 2px solid #ff3e00;\n    border-radius: 20px;\n}\n\n/* #correct-border {\n    border: 2px solid #00ff00;\n} */\n/* .css-tlc64q-MuiPaper-root-MuiDialog-paper{\n    max-width: 800px !important;\n} */\n#saveButtonDropOff {\n    color: white !important;\n}\n#cleanButtonDropOff {\n    color: white !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
