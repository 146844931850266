import ButtonModal from "./components/Button/button";
import { StoreProvider } from "./store/storeContext";
import Modal from "./components/Modal/modal";
import "./App.css";
export default function Root(props) {
  return (
    <StoreProvider>
      <section id="rightPosition">
        <ButtonModal />
        <Modal />
      </section>
    </StoreProvider>
  );
}
