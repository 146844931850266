import * as React from "react";
import { useEventLibrary } from "../services/eventLibrary";
import Cookies from "universal-cookie";
const cookies = new Cookies(null, { path: "/" });
import dayjs from "dayjs";
// store.js
export const StoreContext = React.createContext(null);

export const StoreProvider = ({ children }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [locations, setLocations] = React.useState([]);
  const [sizeTypes, setSizeTypes] = React.useState([]);
  const [sslList, setSslList] = React.useState([]);
  const [displayButton, setDisplayButton] = React.useState(false);
  const [snackbarAction, setSnackbarAction] = React.useState({
    open: false,
    message: "",
    color: "",
  });
  const [update, setUpdate] = React.useState(false);
  const handleSetOpenModal = async (state) => {
    setUpdate(false);
    setOpenModal(state);
  };

  const [dropOffObject, setDropOffObject] = React.useState({
    location: null,
    scacCode: "SNEA",
    carrier: null,
    containerNumber: null,
    ssl: null,
    sizeType: null,
    chassisNumber: null,
    keepChassis: false,
    expectedArrivalDate: dayjs(new Date()),
    userName: null,
    foodGrade: false,
    tmp: null,
    deliveryDate: dayjs(new Date()),
    deliveryLocation: null,
    comment: null,
  });

  function handleSetDropOffObject() {
    if (window.location.href.includes("home")) {
      setDisplayButton(false);
      return;
    } else {
      if (cookies.get("sessionCosmos")) {
        const user = cookies.get("sessionCosmos");
        setUser(cookies.get("sessionCosmos"));
        setDropOffObject({ ...dropOffObject, userName: user.email[0] });
        if (!user.email[0].includes("@snecorp.com")) {
          setDisplayButton(false);
        } else {
          setDisplayButton(true);
        }
      } else {
        cookies.addChangeListener((session) => {
          if (session.name === "sessionCosmos") {
            if (session.value !== undefined) {
              setUser(session.value);
              setDropOffObject({
                ...dropOffObject,
                userName: session.value.email[0],
              });
              if (!session.value.email[0].includes("@snecorp.com")) {
                setDisplayButton(false);
              } else {
                setDisplayButton(true);
              }
            }
          }
        });
      }
    }
  }
  React.useEffect(() => {
    handleSetDropOffObject();

    window.addEventListener("popstate", (e) => {
      handleSetDropOffObject();
    });
  }, []);

  return (
    <StoreContext.Provider
      value={{
        openModal,
        handleSetOpenModal,
        displayButton,
        dropOffObject,
        setDropOffObject,
        setDisplayButton,
        update,
        setUpdate,
        setOpenModal,
        user,
        setUser,
        snackbarAction,
        setSnackbarAction,
        locations,
        setLocations,
        sizeTypes,
        setSizeTypes,
        sslList,
        setSslList,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
