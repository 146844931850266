// button.jsx
import Button from '@mui/material/Button';
import useButtonController from '../Controllers/buttonController';
import Paper from '@mui/material/Paper';
import { GoContainer } from "react-icons/go";
import imgButton from '../../assets/icons/ST.png'
import './button.css';
const ButtonModal = () => {
    const { handleSetOpenModal, displayButton } = useButtonController();

    return (

        displayButton ? (

            <Paper
                id='display-button-modal'
                elevation={4}
                onClick={(e) => handleSetOpenModal(true)}

            >
                <img src={imgButton} style={{ width: '40px', height: '38px', alignSelf: 'center', color: 'white' }} />
            </Paper >

        ) : null
    );
};

export default ButtonModal;
