import * as React from "react";

export const useEventLibrary = async (eventoDispatch, detail, eventToReceive) => {
    const infoEvent = {
        eventName: eventoDispatch,
        detail: detail,
        receiverEvent: eventToReceive,
    };


    return new Promise((resolve, reject) => {

        const handleEventListener = (e) => {
            resolve(e.detail);
            cleanup();
        }
        window.addEventListener(eventToReceive, (e) => {
            handleEventListener(e);
        });
        const cleanup = () => {
            window.removeEventListener(eventToReceive, handleEventListener);
        }
        const eventInLogin = new CustomEvent('eventIntoLogin', { detail: infoEvent });
        window.dispatchEvent(eventInLogin);

    });
};