// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#display-button-modal {
    width: 60px;
    height: 60px;
    background-color: #FC4817;
    justify-content: center;
    cursor: pointer;
    opacity: 100%;
    display: flex;
    transition: 0.3s ease-in-out;
}

`, "",{"version":3,"sources":["webpack://./src/components/Button/button.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,uBAAuB;IACvB,eAAe;IACf,aAAa;IACb,aAAa;IACb,4BAA4B;AAChC","sourcesContent":["#display-button-modal {\n    width: 60px;\n    height: 60px;\n    background-color: #FC4817;\n    justify-content: center;\n    cursor: pointer;\n    opacity: 100%;\n    display: flex;\n    transition: 0.3s ease-in-out;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
