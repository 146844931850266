import { axiosInstance } from "./appInsights";

const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
const headersDrayPower = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY_DRAYPOWER,
  "Content-Type": "application/json",
};
export const getDropdownsOptions = async () => {
  try {
    const responseSizeTypes = await axiosInstance.get(
      process.env.REACT_APP_GET_SIZE_TYPE_URL
    );
    const responseSslList = await axiosInstance.get(
      process.env.REACT_APP_GET_SSL_LIST_URL
    );

    const response = {
      sizeTypes: responseSizeTypes.data,
      sslList: responseSslList.data,
    };
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getLocationsByOwner = async (ScacCode) => {
  try {
    const config = {
      headers: headersDrayPower,
      method: "get",
      params: { ScacCodeOwner: ScacCode },
      url: process.env.REACT_APP_GET_LOCATIONS_BY_OWNER,
      nameEvent: "StreetTurnDropOff-getLocationsByOwner",
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createDropOff = async (data) => {
  try {
    const config = {
      headers: headers,
      method: "post",
      url: process.env.REACT_APP_CREATE_DROP_OFF_URL,
      data: data,
      nameEvent: "StreetTurnDropOff-createDropOff",
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const checkUser = async (entity) => {
  try {
    const config = {
      headers: headersDrayPower,
      method: "post",
      url: process.env.REACT_APP_CHECK_USER_URL,
      data: entity,
      nameEvent: "StreetTurnDropOff-checkUser",
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const GetDropOffInTransitByContainer = async (ContainerNumber) => {
  try {
    const config = {
      headers: headers,
      method: "get",
      url: process.env.REACT_APP_GET_DROP_OFF_IN_TRANSIT,
      params: { ContainerNumber },
      nameEvent: "StreetTurnDropOff-GetDropOffInTransitByContainer",
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateDropOff = async (data) => {
  try {
    const config = {
      headers: headers,
      method: "post",
      url: process.env.REACT_APP_UPDATE_DROP_OFF_INTRANSIT,
      data: data,
      nameEvent: "StreetTurnDropOff-updateDropOff",
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    console.error(error);
  }
};
