import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { Autocomplete, Box, TextField, InputLabel, MenuItem, Typography, FormControl, Select, RadioGroup, FormControlLabel, FormLabel, Radio, Button, CircularProgress, Backdrop } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useModalController from '../Controllers/modalController';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SnackbarInfo from '../Snackbar/snackbar';
import './modal.css';

const ResponsiveDialog = () => {
    const {
        openModal,
        setOpenModal,
        loading,
        locations,
        sizeTypes,
        sslList,
        buttonSaveDisabled,
        dropOffObject,
        setDropOffObject,
        saveDropOff,
        validationObject,
        saving,
        snackbarActionSuccess,
        snackbarActionError,
        dropOffId,
        minDateDeliver,
        setMinDateDeliver,
        minDateExpectedArrival,
        setMinDateExpectedArrival,
        update,
        cleanDropOff
    } = useModalController();

    const theme = useTheme();

    return (
        <>
            <Dialog
                fullWidth={true}
                open={openModal}
                onClose={(e) => setOpenModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ zIndex: 33 }}
                PaperProps={{
                    sx: {
                        [theme.breakpoints.up('md')]: {
                            minWidth: '800px'
                        }
                    }
                }}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={saving}
                >
                    <CircularProgress style={{ scale: '3' }} color="primary" />
                </Backdrop>
                <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: '#FC4817', color: 'white', textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {"Quick Check In for St Drop Off"}
                    <IconButton
                        aria-label="close"
                        onClick={(e) => setOpenModal(false)}
                        sx={{
                            color: 'white',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ flexGrow: 1 }} margin={'4%'} >
                        <Grid container spacing={2} display={'flex'}>
                            <Grid xs={12} sm={6} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Location *</InputLabel>
                                    <Select
                                        disabled={update ? true : false}
                                        label="Select Location"
                                        error={buttonSaveDisabled === false && !validationObject.locationValidation ? true : false}
                                        value={dropOffObject.location ? dropOffObject.location : ''}
                                        onChange={(e) => { setDropOffObject({ ...dropOffObject, location: e.target.value }) }}
                                    >
                                        {loading ? <MenuItem>Loading...</MenuItem> :
                                            locations.map((location) => {
                                                return <MenuItem key={location.value} value={location.value}>{location.text}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    error={buttonSaveDisabled === false && !validationObject.scacValidation ? true : false}
                                    label="Carrier SCAC *"
                                    type="search"
                                    variant="outlined"
                                    id="standard-search"
                                    value={dropOffObject.scacCode ? dropOffObject.scacCode : ''}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 4)
                                            setDropOffObject({ ...dropOffObject, scacCode: e.target.value.toUpperCase() });
                                    }}
                                />

                            </Grid>
                            <Grid xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    id="standard-search"
                                    label="Carrier"
                                    type="search"
                                    variant="outlined"
                                    value={dropOffObject.carrier ? dropOffObject.carrier : ''}
                                    onChange={(e) => setDropOffObject({ ...dropOffObject, carrier: e.target.value.toUpperCase() })}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    error={buttonSaveDisabled === false && !validationObject.container_tmp_numberValidation ? true : false}
                                    label="Container Number *"
                                    type="search"
                                    disabled={update ? true : false}
                                    variant="outlined"
                                    value={dropOffObject.containerNumber ? dropOffObject.containerNumber : ''}
                                    inputProps={{
                                        maxLength: 11,
                                    }}
                                    onChange={(e) => {
                                        if (/^[A-Za-z0-9]*$/.test(e.target.value)) {
                                            if (e.target.value !== '' && dropOffObject.tmp !== null && dropOffObject.tmp !== '') {
                                                if (dropOffObject.tmp.startsWith('UNK1-')) {
                                                    const newValue = dropOffObject.tmp.substring(5);
                                                    setDropOffObject({ ...dropOffObject, tmp: newValue, containerNumber: e.target.value.toUpperCase() });
                                                }
                                                else {
                                                    setDropOffObject({ ...dropOffObject, containerNumber: e.target.value.toUpperCase() })
                                                }
                                            } else if (e.target.value === '' && dropOffObject.tmp !== null && dropOffObject.tmp !== '') {
                                                {
                                                    setDropOffObject({ ...dropOffObject, tmp: 'UNK1-' + dropOffObject.tmp, containerNumber: e.target.value.toUpperCase() })
                                                }
                                            } else {
                                                setDropOffObject({ ...dropOffObject, containerNumber: e.target.value.toUpperCase() })
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} md={4}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo-ssl"
                                    options={sslList ? sslList.filter((sslItem) => sslItem.scacCode) : []}
                                    getOptionLabel={(option) => option.scacCode || ''}
                                    value={dropOffObject.ssl ? dropOffObject.ssl : null}
                                    onChange={(event, newValue) => {
                                        if (newValue)
                                            setDropOffObject({ ...dropOffObject, ssl: newValue });
                                        else
                                            setDropOffObject({ ...dropOffObject, ssl: '' });

                                    }}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} label="SSL" display={'flex'} />}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ fontSize: '70%' }}>{option.scacCode}</p>
                                                {option.scacCode && (
                                                    <p style={{ fontSize: '60%', color: '#9c9c9c' }}>- {option.name} </p>
                                                )}
                                            </div>
                                        </li>
                                    )}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">S/T</InputLabel>
                                    {loading ?
                                        <MenuItem>Loading...</MenuItem>
                                        :
                                        <Select
                                            label="Size/Type"
                                            value={dropOffObject.sizeType ? dropOffObject.sizeType : ''}
                                            disabled={loading ? true : false}
                                            onChange={(e) => { setDropOffObject({ ...dropOffObject, sizeType: e.target.value }) }}
                                        >
                                            {
                                                sizeTypes.map((sizeType) => {
                                                    return <MenuItem key={sizeType.value} value={sizeType.value}>{sizeType.text}</MenuItem>
                                                })
                                            }
                                        </Select>}

                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} borderRadius={'10px'}>
                                <TextField
                                    fullWidth
                                    label="Chassis Number"
                                    disabled={update ? true : false}
                                    variant="outlined"
                                    type="search"
                                    inputProps={{
                                        maxLength: 17
                                    }}
                                    value={dropOffObject.chassisNumber ? dropOffObject.chassisNumber : ''}
                                    onChange={(e) => {
                                        if (/^[A-Za-z0-9]*$/.test(e.target.value) || e.target.value === '') {
                                            setDropOffObject({ ...dropOffObject, chassisNumber: e.target.value.toUpperCase() })
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} md={4} justifyContent={'center'} display={'flex'}>
                                <TextField
                                    fullWidth
                                    id="standard-search"
                                    disabled={update ? true : false}
                                    error={buttonSaveDisabled === false && !validationObject.container_tmp_numberValidation ? true : false}
                                    label="TMP"
                                    variant="outlined"
                                    value={dropOffObject.tmp ? dropOffObject.tmp : ''}
                                    onChange={(e) => {
                                        let inputValue = e.target.value.toUpperCase();
                                        if (dropOffObject.containerNumber === null || dropOffObject.containerNumber === '') {
                                            if (!inputValue.startsWith('UNK1-')) {
                                                if (/^\d+$/.test(inputValue)) {
                                                    inputValue = `UNK1-${inputValue}`;
                                                } else if (inputValue.startsWith('UNK') && inputValue.length <= 4) {
                                                    inputValue = inputValue;
                                                } else if (inputValue === '' || /^[A-Z]*$/.test(inputValue)) {
                                                    inputValue = inputValue;
                                                } else {
                                                    return;
                                                }
                                            }
                                        }
                                        setDropOffObject({ ...dropOffObject, tmp: inputValue });
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} md={4} justifyContent={'center'} display={'flex'}>
                                <FormControl style={{ textAlign: 'center' }} >
                                    <FormLabel id="demo-row-radio-buttons-group-label">Keep Chassis?</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={dropOffObject.keepChassis ? 'true' : 'false'}
                                        onChange={(e) => setDropOffObject({ ...dropOffObject, keepChassis: e.target.value === 'true' })}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} display={'inline-table'}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            minDate={minDateExpectedArrival}
                                            label="Expected Arrival"
                                            value={dropOffObject.expectedArrivalDate ? dropOffObject.expectedArrivalDate : dayjs(new Date())}
                                            onChange={(newValue) => {
                                                setMinDateDeliver(newValue)
                                                if (dropOffObject.deliveryDate < newValue)
                                                    setDropOffObject({ ...dropOffObject, deliveryDate: newValue, expectedArrivalDate: newValue })
                                                else {
                                                    setDropOffObject({ ...dropOffObject, expectedArrivalDate: newValue })
                                                }
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} justifyContent={'center'} display={'inline-table'}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            minDate={minDateDeliver}
                                            label="Delivery Date"
                                            value={dropOffObject.deliveryDate ? dropOffObject.deliveryDate : null}
                                            onChange={(newValue) => {
                                                setDropOffObject({ ...dropOffObject, deliveryDate: newValue })
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid xs={12} sm={6} md={4} justifyContent={'center'} display={'flex'}>
                                <FormControl style={{ textAlign: 'center' }}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Food Grade?</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={dropOffObject.foodGrade ? 'true' : 'false'}
                                        onChange={(e) => {
                                            setDropOffObject({ ...dropOffObject, foodGrade: e.target.value === 'true' })
                                        }}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} xl={12} xxl={12}>
                                <TextField
                                    fullWidth
                                    id="standard-search"
                                    label="Customer/Delivery Location"
                                    variant="outlined"
                                    value={dropOffObject.deliveryLocation ? dropOffObject.deliveryLocation : ''}
                                    onChange={(e) => {
                                        setDropOffObject({ ...dropOffObject, deliveryLocation: e.target.value });
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} sm={12} md={12} xl={12} xxl={12}>
                                <TextField
                                    id="filled-multiline-static"
                                    label="Comments"
                                    multiline
                                    rows={3}
                                    fullWidth
                                    value={dropOffObject.comment ? dropOffObject.comment : ''}
                                    variant="filled"
                                    onChange={(e) => setDropOffObject({ ...dropOffObject, comment: e.target.value })}
                                />
                            </Grid>
                            <Grid xs={0} sm={0} md={4} />
                            <Grid md={4} display={'flex'} justifyContent={'center'}></Grid>
                            <Grid xs={12} sm={12} md={4} display={'flex'} justifyContent={'center'}>
                                <Button
                                    onClick={() => saveDropOff()}
                                    id="saveButtonDropOff"
                                    variant="contained" style={{ backgroundColor: '#29C48A', width: '100%' }}
                                >{update ? "Update" : "Save"}</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                {snackbarActionSuccess && (
                    <SnackbarInfo color={'#1ba345'} message={(update ? 'Drop Off Updated Succesfully - Drop Off Id  :' : 'Drop Off created succesfully - Receipt Number:') + dropOffId} />
                )}
                {snackbarActionError && (
                    <SnackbarInfo color={'#de3e44'} message={dropOffId} />
                )}
            </Dialog>
        </>
    );
};

export default ResponsiveDialog;
