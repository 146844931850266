import axios from "axios";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

// Configuración de Application Insights
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
    enableRequestPayloadTracking: true,
    enableResponsePayloadTracking: true,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
  },
});

appInsights.loadAppInsights();

// Creación de la instancia de Axios con interceptores
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  function (config) {
    // Almacena el tiempo de inicio de la solicitud
    config.metadata = { startTime: new Date() };
    return config;
  },
  function (error) {
    // Manejo de errores en el request
    appInsights.trackException({ exception: error });
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    const { config } = response;
    const { method, url, headers, params, data } = config;
    const { status, statusText } = response;
    const responseData = response.data;

    // Calcula el tiempo de fin de la solicitud
    const endTime = new Date();
    const duration = endTime - config.metadata.startTime;

    // Parsear los datos de la solicitud y la respuesta como objetos JSON
    let parsedRequestData;
    let parsedResponseData;

    try {
      parsedRequestData = JSON.parse(JSON.stringify(data ? data : {}));
      parsedResponseData = JSON.parse(JSON.stringify(responseData));
    } catch (error) {
      console.error("Error parsing JSON data:", error);
    }

    // Registrar el evento con la respuesta
    appInsights.trackEvent({
      name: config.nameEvent || "API Response",
      properties: {
        method,
        url,
        headers: headers, // Ya es un objeto JSON
        params: params, // Ya es un objeto JSON
        requestData: parsedRequestData,
        status,
        statusText,
        responseData: parsedResponseData,
        duration,
      },
    });

    return response;
  },
  function (error) {
    // Manejo de errores en el response
    appInsights.trackException({ exception: error });
    return Promise.reject(error);
  }
);

export { axiosInstance, appInsights };
